import React from 'react';
import { useI18n } from '@paprika/l10n';
import Modal from '@paprika/modal';
import Textarea from '@paprika/textarea';
import Button from '@paprika/button';
import FormElement from '@paprika/form-element';
import Checkbox from '@paprika/checkbox';
import tokens from '@paprika/tokens';
import InfoCircleIcon from '@acl-services/wasabicons/lib/InfoCircle';

import './Modals.scss';
import Popover from '@paprika/popover';

type Props = {
  onCancel: () => void;
  onCommit: (commitMsg: string, saveOutput: boolean) => void;
  defaultSaveOutput: boolean;
};

export default function CommitModal({ onCancel, onCommit, defaultSaveOutput }: Props) {
  const [commitMsg, setCommitMsg] = React.useState<string>('');
  const [saveOutput, setSaveOutput] = React.useState<boolean>(defaultSaveOutput);

  const I18n = useI18n();
  const checkedState = saveOutput ? Checkbox.types.state.CHECKED : Checkbox.types.state.UNCHECKED;

  return (
    <Modal isOpen size="small" onClose={onCancel} zIndex={7}>
      <Modal.Header onClose={onCancel}>{I18n.t('commit_modal.heading')}</Modal.Header>
      <Modal.Content>
        <FormElement>
          <FormElement.Label className="commit-modal__label">{I18n.t('commit_modal.message_label')}</FormElement.Label>
          <FormElement.Content>
            {(a11yProps) => (
              <>
                <Textarea {...a11yProps} value={commitMsg} onChange={(e) => setCommitMsg(e.target.value)} />
                <div className="commit-modal__checkbox-wrapper">
                  <Checkbox
                    checkedState={checkedState}
                    onChange={() => {
                      setSaveOutput((oldState) => !oldState);
                    }}
                  >
                    {I18n.t('commit_modal.save_output_label')}
                  </Checkbox>

                  <Popover isEager zIndex={8}>
                    <Popover.Trigger>
                      <InfoCircleIcon
                        className="commit-modal__popover-trigger"
                        color={tokens.color.blackLighten30}
                        size="18px"
                      />
                    </Popover.Trigger>
                    <Popover.Content>
                      <Popover.Tip zIndex={9} />
                      <Popover.Card>{I18n.t('commit_modal.save_output_tooltip')}</Popover.Card>
                    </Popover.Content>
                  </Popover>
                </div>
              </>
            )}
          </FormElement.Content>
        </FormElement>
      </Modal.Content>
      <Modal.Footer>
        <Button kind="primary" onClick={() => onCommit(commitMsg, saveOutput)} isDisabled={commitMsg === ''}>
          {I18n.t('commit_modal.confirm_button')}
        </Button>
        <Button onClick={onCancel}>{I18n.t('commit_modal.cancel_button')}</Button>
      </Modal.Footer>
    </Modal>
  );
}
